<template>
  <div class="about">
    <div class="titles azjgs">
      <div>
        <input-list v-show="changes && ctype[0] != 4" @setData="getData" iptType="1"></input-list>
        <div v-show="changes && ctype[0] != 4">
          设备名称：
          <el-select ref="selectDev" @visible-change="$visibleChange($event, 'selectDev')" v-model="csbmc" placeholder="全部" clearable filterable>
            <el-option :label="item.name" v-for="(item, index) in csbmcArr" :key="index" :value="item.id"></el-option>
          </el-select>
        </div>
        <div v-if="ctype[0] == 6" v-show="changes">
          用户名：
          <el-input v-model="seaName" placeholder="请输入用户名" clearable></el-input>
        </div>
        <div v-if="ctype[0] == 17" v-show="changes">
          车牌类型：
          <el-select ref="plateType" @visible-change="$visibleChange($event, 'plateType')" v-model="plateType" placeholder="全部" clearable filterable>
            <el-option :label="item.name" v-for="(item, index) in plateTypeArr" :key="index" :value="item.id"></el-option>
          </el-select>
        </div>
        <div v-if="ctype[0] == 17" v-show="changes">
          车牌号码：
          <el-input v-model="plateNum" placeholder="请输入车牌号码" clearable></el-input>
        </div>
        <div v-if="ctype[0] == 17" v-show="changes">
          警灯颜色：
          <el-select ref="alarmTag" @visible-change="$visibleChange($event, 'alarmTag')" v-model="alarmTag" placeholder="全部" clearable filterable>
            <el-option label="绿" value="0"></el-option>
            <el-option label="红" value="1"></el-option>
          </el-select>
        </div>
        <div v-show="!changes">
          查询日期类型：
          <el-select v-model="stypes" class="types" @change="types">
            <el-option label="按天" value="0"></el-option>
            <el-option label="按月" value="1"></el-option>
            <el-option label="按年" value="2"></el-option>
          </el-select>
        </div>
        <div v-show="changes && ctype[0] == 4">
          设备名称:
          <el-select filterable ref="selectDev" @visible-change="$visibleChange($event, 'selectDev')" v-model="personDev" placeholder="全部" clearable>
            <el-option :label="item.name" v-for="(item, index) in personDevArr" :key="index" :value="item.id"></el-option>
          </el-select>
        </div>
        <div>
          查询时间：
          <el-date-picker v-model="value2" v-if="daymonth" type="daterange" align="right" size="small" time-arrow-control key="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="display: inline-flex; width: 210px; height: 34px" value-format="yyyy-MM-dd"> </el-date-picker>
          <el-date-picker v-model="value2" v-if="!daymonth" :clearable="false" :editable="false" :key="keys" :type="dateranges" size="small" :placeholder="placeholders" :format="formats" :value-format="values"> </el-date-picker>
        </div>
        <span style="float: right" class="btns">
          <el-button v-if="$has(ctype[1])" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
          <el-dropdown v-if="$has(ctype[2])" trigger="click" @command="handleClick">
            <span class="el-dropdown-link"> <i class="el-icon-upload2" style="margin-right: 5px"></i>导出 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0" class="iconfont icon-excel">Excel</el-dropdown-item>
              <el-dropdown-item command="1" class="iconfont icon-pdf">PDF</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-if="$has(ctype[1]) && ctype[0] != 17" type="warning" size="mini" icon="el-icon-sort" @click="switchs">切 换</el-button>
        </span>
      </div>
    </div>
    <div v-if="ctype[0] == 17" class="proText" style="font-size: 12px"><span style="color: red">*</span> 注:双击表格可查看详情</div>
    <div class="cont">
      <div class="first" v-show="changes">
        <div :class="{ chartSty: true, chartOne: chartNum == 1, chartTwo: chartNum == 2, chartFour: chartNum == 4, chartSix: chartNum == 6 }" id="pdfDom">
          <div v-for="item in chartNum" :key="item.index">
            <div v-if="isShow" style="height: 100%; width: 100%">
              <div :class="'emain' + item" :ref="'emain' + item"></div>
            </div>
            <div class="echartsnone" v-if="!isShow">暂无数据</div>
          </div>
          <div class="textSty" v-if="!isText && isShow && ctype[0] == 1">环境辐射剂量符合国标要求，建议保持并关注放射工作人员个人剂量。</div>
          <div class="textSty" v-if="isText && isShow && ctype[0] == 1">总体环境辐射剂量符合国标要求，部分点位环境辐射剂量超标，请立即检查放射源及射线装置的使用情况，并核实相关辐射防护措施是否落实到位，必要时启动相关辐射安全应急保障措施。</div>
        </div>
        <div style="height: 100%; width: 100%">
          <el-table v-if="ctype[0] == 17" ref="table" :data="tableData" @row-dblclick="rowClick" height="calc(100% - 40px)">
            <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
            <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="licensePlateNumber" label="车牌">
              <template slot-scope="{ row }">{{ row.licensePlateType == 1 ? '蓝' : row.licensePlateType == 2 ? '黄' : '无' }} {{ ' ' + row.licensePlateNumber }}</template>
            </el-table-column>
            <el-table-column prop="alarmTag" label="警灯颜色">
              <template slot-scope="{ row }">{{ row.alarmTag == 0 ? '绿' : '红' }}</template>
            </el-table-column>
            <el-table-column prop="speed" label="车速(km/h)"></el-table-column>
            <el-table-column prop="temperature" label="温度(℃)"></el-table-column>
            <el-table-column prop="humidity" label="湿度(%RH)"></el-table-column>
            <el-table-column prop="createDt" label="产生时间" width="160"></el-table-column>
            <div slot="empty" class="empty">
              <img src="../../../public/home/noData.svg" width="320" alt="" />
              <span>暂无数据</span>
            </div>
          </el-table>
          <el-table v-else-if="ctype[0] == 6" ref="table" :data="tableData" height="calc(100% - 40px)">
            <el-table-column prop="areaName" label="区域名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="用户名" show-overflow-tooltip></el-table-column>
            <el-table-column label="左手手心">
              <el-table-column prop="leftPlamGamma" label="γ剂量率(μSv/h)">
                <div slot-scope="{ row }" :class="{ isWarn: row.leftPlamGamma < thresholdArrAll[0][0] || row.leftPlamGamma > thresholdArrAll[0][1] }">{{ row.leftPlamGamma }}</div>
              </el-table-column>
              <el-table-column prop="leftPlamBeta" label="β活度(Bq/cm²)">
                <div slot-scope="{ row }" :class="{ isWarn: row.leftPlamBeta < thresholdArrAll[1][0] || row.leftPlamBeta > thresholdArrAll[1][1] }">{{ row.leftPlamBeta }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column label="左手手背">
              <el-table-column prop="leftBackGamma" label="γ剂量率(μSv/h)">
                <div slot-scope="{ row }" :class="{ isWarn: row.leftBackGamma < thresholdArrAll[0][0] || row.leftBackGamma > thresholdArrAll[0][1] }">{{ row.leftBackGamma }}</div>
              </el-table-column>
              <el-table-column prop="leftBackBeta" label="β活度(Bq/cm²)">
                <div slot-scope="{ row }" :class="{ isWarn: row.leftBackBeta < thresholdArrAll[1][0] || row.leftBackBeta > thresholdArrAll[1][1] }">{{ row.leftBackBeta }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column label="右手手心">
              <el-table-column prop="rightPlamGamma" label="γ剂量率(μSv/h)">
                <div slot-scope="{ row }" :class="{ isWarn: row.rightPlamGamma < thresholdArrAll[0][0] || row.rightPlamGamma > thresholdArrAll[0][1] }">{{ row.rightPlamGamma }}</div>
              </el-table-column>
              <el-table-column prop="rightPlamBeta" label="β活度(Bq/cm²)">
                <div slot-scope="{ row }" :class="{ isWarn: row.rightPlamBeta < thresholdArrAll[1][0] || row.rightPlamBeta > thresholdArrAll[1][1] }">{{ row.rightPlamBeta }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column label="右手手背">
              <el-table-column prop="rightBackGamma" label="γ剂量率(μSv/h)">
                <div slot-scope="{ row }" :class="{ isWarn: row.rightBackGamma < thresholdArrAll[0][0] || row.rightBackGamma > thresholdArrAll[0][1] }">{{ row.rightBackGamma }}</div>
              </el-table-column>
              <el-table-column prop="rightBackBeta" label="β活度(Bq/cm²)">
                <div slot-scope="{ row }" :class="{ isWarn: row.rightBackBeta < thresholdArrAll[1][0] || row.rightBackBeta > thresholdArrAll[1][1] }">{{ row.rightBackBeta }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column label="左脚">
              <el-table-column prop="leftFootGamma" label="γ剂量率(μSv/h)">
                <div slot-scope="{ row }" :class="{ isWarn: row.leftFootGamma < thresholdArrAll[0][0] || row.leftFootGamma > thresholdArrAll[0][1] }">{{ row.leftFootGamma }}</div>
              </el-table-column>
              <el-table-column prop="leftFootBeta" label="β活度(Bq/cm²)">
                <div slot-scope="{ row }" :class="{ isWarn: row.leftFootBeta < thresholdArrAll[1][0] || row.leftFootBeta > thresholdArrAll[1][1] }">{{ row.leftFootBeta }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column label="右脚">
              <el-table-column prop="rightFootGamma" label="γ剂量率(μSv/h)">
                <div slot-scope="{ row }" :class="{ isWarn: row.rightFootGamma < thresholdArrAll[0][0] || row.rightFootGamma > thresholdArrAll[0][1] }">{{ row.rightFootGamma }}</div>
              </el-table-column>
              <el-table-column prop="rightFootBeta" label="β活度(Bq/cm²)">
                <div slot-scope="{ row }" :class="{ isWarn: row.rightFootBeta < thresholdArrAll[1][0] || row.rightFootBeta > thresholdArrAll[1][1] }">{{ row.rightFootBeta }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="createDt" label="产生时间" width="100"></el-table-column>
            <div slot="empty" class="empty">
              <img src="../../../public/home/noData.svg" width="320" alt="" />
              <span>暂无数据</span>
            </div>
          </el-table>
          <el-table v-else-if="ctype[0] == 18" ref="table" :data="tableData" height="calc(100% - 40px)">
            <el-table-column prop="areaName" label="区域名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="pedestrianNum" label="累计行人数量(每次上电后归零)"></el-table-column>
            <el-table-column prop="alarmTag" label="是否报警">
              <template slot-scope="{ row }">{{ row.alarmTag == 0 ? '否' : '是' }}</template>
            </el-table-column>
            <el-table-column label="探测器1">
              <el-table-column prop="probe1Background" label="本底值(cps)"></el-table-column>
              <el-table-column prop="probe1Cps" label="计数率(cps)"></el-table-column>
              <!-- <el-table-column prop="probe1Ms" label="剂量率(μSv/h)"></el-table-column> -->
              <el-table-column prop="probe1Threshold" label="阈值(cps)"></el-table-column>
            </el-table-column>
            <el-table-column label="探测器2">
              <el-table-column prop="probe2Background" label="本底值(cps)"></el-table-column>
              <el-table-column prop="probe2Cps" label="计数率(cps)"></el-table-column>
              <!-- <el-table-column prop="probe2Ms" label="剂量率(μSv/h)"></el-table-column> -->
              <el-table-column prop="probe2Threshold" label="阈值(cps)"></el-table-column>
            </el-table-column>
            <el-table-column prop="createDt" label="产生时间" width="100"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <el-button @click="openDialog(row)" size="small" icon="el-icon-tickets">详 情</el-button>
              </template>
            </el-table-column>
            <div slot="empty" class="empty">
              <img src="../../../public/home/noData.svg" width="320" alt="" />
              <span>暂无数据</span>
            </div>
          </el-table>
          <el-table v-else-if="ctype[0] == 19" ref="table" :data="tableData" height="calc(100% - 40px)">
            <el-table-column prop="areaName" label="区域名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="temp" label="水温(℃)">
              <div slot-scope="{ row }" :class="{ isWarn: row.temp < thresholdArrAll[3][0] || row.temp > thresholdArrAll[3][1] }">{{ row.temp }}</div>
            </el-table-column>
            <el-table-column label="原水">
              <el-table-column prop="orgTds" label="TDS">
                <div slot-scope="{ row }" :class="{ isWarn: row.orgTds < thresholdArrAll[0][0] || row.orgTds > thresholdArrAll[0][1] }">{{ row.orgTds }}</div>
              </el-table-column>
              <el-table-column prop="orgToc" label="TOC">
                <div slot-scope="{ row }" :class="{ isWarn: row.orgToc < thresholdArrAll[1][0] || row.orgToc > thresholdArrAll[1][1] }">{{ row.orgToc }}</div>
              </el-table-column>
              <el-table-column prop="orgCod" label="COD">
                <div slot-scope="{ row }" :class="{ isWarn: row.orgCod < thresholdArrAll[2][0] || row.orgCod > thresholdArrAll[2][1] }">{{ row.orgCod }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column label="净水">
              <el-table-column prop="tds" label="TDS">
                <div slot-scope="{ row }" :class="{ isWarn: row.tds < thresholdArrAll[0][0] || row.tds > thresholdArrAll[0][1] }">{{ row.tds }}</div>
              </el-table-column>
              <el-table-column prop="toc" label="TOC">
                <div slot-scope="{ row }" :class="{ isWarn: row.toc < thresholdArrAll[1][0] || row.toc > thresholdArrAll[1][1] }">{{ row.toc }}</div>
              </el-table-column>
              <el-table-column prop="cod" label="COD">
                <div slot-scope="{ row }" :class="{ isWarn: row.cod < thresholdArrAll[2][0] || row.cod > thresholdArrAll[2][1] }">{{ row.cod }}</div>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="orgSampleDt" label="原水采样时间" width="100"></el-table-column>
            <el-table-column prop="sampleDt" label="净水采样时间" width="100"></el-table-column>
            <el-table-column prop="createDt" label="产生时间" width="100"></el-table-column>
            <div slot="empty" class="empty">
              <img src="../../../public/home/noData.svg" width="320" alt="" />
              <span>暂无数据</span>
            </div>
          </el-table>
          <el-table v-else-if="ctype[0] == 20" ref="table" :data="tableData" height="calc(100% - 40px)">
            <el-table-column prop="areaName" label="区域名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip></el-table-column>
            <el-table-column sortable v-for="item in columnArr" :key="item.index" :prop="item.prop" :label="item.label"></el-table-column>
            <el-table-column prop="createDt" label="产生时间" width="100"></el-table-column>
          </el-table>
          <el-table v-else ref="table" :data="tableData" height="calc(100% - 40px)">
            <el-table-column :show-overflow-tooltip="item.prop != 'createDt'" v-for="item in tableArr" :key="item.index" :prop="item.prop" :label="item.label" sortable>
              <div slot-scope="{ row }" :class="{ isWarn: (item.prop == 'doserate' || item.prop == 'neutron') && (row[item.prop] < thresholdArrAll[0][0] || row[item.prop] > thresholdArrAll[0][1]) }">{{ row[item.prop] }}</div>
            </el-table-column>
            <div slot="empty" class="empty">
              <img src="../../../public/home/noData.svg" width="320" alt="" />
              <span>暂无数据</span>
            </div>
          </el-table>
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
        </div>
        <ToTop />
      </div>
      <div class="second" v-show="!changes">
        <div :class="{ chartSty: true, chartTwo: chartNum == 2, chartFour: chartNum == 4, chartSix: chartNum == 6 }">
          <div v-for="item in chartNum" :key="item.index">
            <div v-if="newvals" style="height: 100%">
              <div :class="'chart' + item" :ref="'chart' + item"></div>
            </div>
            <div class="echartsnone" v-if="!newvals">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <el-dialog title="详细数据" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag @close="cancles" center>
      <div class="dialogsC" v-if="dialogVisible">
        <div class="newDialog" v-if="ctype[0] == 17">
          <el-image :src="imgSrc" :preview-src-list="srcList"> </el-image>
          <div>
            <span>管辖区域：</span>
            <span>{{ paramsObj.areaName }}</span>
          </div>
          <div>
            <span>机构名称：</span>
            <span>{{ paramsObj.organizationName }}</span>
          </div>
          <div>
            <span>设备名称：</span>
            <span>{{ paramsObj.deviceName }}</span>
          </div>
          <div>
            <span>车牌：</span>
            <span>{{ paramsObj.licensePlateNumberColor }}</span>
          </div>
          <div>
            <span>警灯颜色：</span>
            <span>{{ paramsObj.alarmTagChi }}</span>
          </div>
          <div>
            <span>车速：</span>
            <span>{{ paramsObj.speed }} (km/h)</span>
          </div>
          <div>
            <span>温度：</span>
            <span>{{ paramsObj.temperature }} (℃)</span>
          </div>
          <div>
            <span>湿度：</span>
            <span>{{ paramsObj.humidity }} (%RH)</span>
          </div>

          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe1.probeCPS > paramsObj.licensePlateCircuit.probe1.probeCPSThreshold }">
            <span>γ1#探测器计数：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe1.probeCPS }}</span>
          </div>
          <div>
            <span>γ1#探测器计数阈值：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe1.probeCPSThreshold }}</span>
          </div>
          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe1.probeMS > paramsObj.licensePlateCircuit.probe1.probeMSThreshold }">
            <span>γ1#探测器剂量(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe1.probeMS }}</span>
          </div>
          <div>
            <span>γ1#探测器剂量阈值(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe1.probeMSThreshold }}</span>
          </div>

          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe2.probeCPS > paramsObj.licensePlateCircuit.probe2.probeCPSThreshold }">
            <span>γ2#探测器计数：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe2.probeCPS }}</span>
          </div>
          <div>
            <span>γ2#探测器计数阈值：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe2.probeCPSThreshold }}</span>
          </div>
          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe2.probeMS > paramsObj.licensePlateCircuit.probe2.probeMSThreshold }">
            <span>γ2#探测器剂量(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe2.probeMS }}</span>
          </div>
          <div>
            <span>γ2#探测器剂量阈值(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe2.probeMSThreshold }}</span>
          </div>

          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe5.probeCPS > paramsObj.licensePlateCircuit.probe5.probeCPSThreshold }">
            <span>γ3#探测器计数：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe5.probeCPS }}</span>
          </div>
          <div>
            <span>γ3#探测器计数阈值：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe5.probeCPSThreshold }}</span>
          </div>
          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe5.probeMS > paramsObj.licensePlateCircuit.probe5.probeMSThreshold }">
            <span>γ3#探测器剂量(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe5.probeMS }}</span>
          </div>
          <div>
            <span>γ3#探测器剂量阈值(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe5.probeMSThreshold }}</span>
          </div>

          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe6.probeCPS > paramsObj.licensePlateCircuit.probe6.probeCPSThreshold }">
            <span>γ4#探测器计数：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe6.probeCPS }}</span>
          </div>
          <div>
            <span>γ4#探测器计数阈值：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe6.probeCPSThreshold }}</span>
          </div>
          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe6.probeMS > paramsObj.licensePlateCircuit.probe6.probeMSThreshold }">
            <span>γ4#探测器剂量(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe6.probeMS }}</span>
          </div>
          <div>
            <span>γ4#探测器剂量阈值(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe6.probeMSThreshold }}</span>
          </div>

          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe3.probeCPS > paramsObj.licensePlateCircuit.probe3.probeCPSThreshold }">
            <span>1#中子探测器计数：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe3.probeCPS }}</span>
          </div>
          <div>
            <span>1#中子探测器计数阈值：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe3.probeCPSThreshold }}</span>
          </div>
          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe3.probeMS > paramsObj.licensePlateCircuit.probe3.probeMSThreshold }">
            <span>1#中子探测器剂量(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe3.probeMS }}</span>
          </div>
          <div>
            <span>1#中子探测器剂量阈值(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe3.probeMSThreshold }}</span>
          </div>

          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe4.probeCPS > paramsObj.licensePlateCircuit.probe4.probeCPSThreshold }">
            <span>2#中子探测器计数：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe4.probeCPS }}</span>
          </div>
          <div>
            <span>2#中子探测器计数阈值：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe4.probeCPSThreshold }}</span>
          </div>
          <div :class="{ bgc: paramsObj.licensePlateCircuit.probe4.probeMS > paramsObj.licensePlateCircuit.probe4.probeMSThreshold }">
            <span>2#中子探测器剂量(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe4.probeMS }}</span>
          </div>
          <div>
            <span>2#中子探测器剂量阈值(μSv/h)：</span>
            <span>{{ paramsObj.licensePlateCircuit.probe4.probeMSThreshold }}</span>
          </div>
          <div>
            <span>产生时间：</span>
            <span>{{ paramsObj.createDt }}</span>
          </div>
        </div>
        <div class="newDialog" style="width: 100%" v-if="ctype[0] == 18">
          <div>
            <span>管辖区域：</span>
            <span>{{ paramsObj.areaName }}</span>
          </div>
          <div>
            <span>机构名称：</span>
            <span>{{ paramsObj.organizationName }}</span>
          </div>
          <div>
            <span>设备名称：</span>
            <span>{{ paramsObj.deviceName }}</span>
          </div>
          <div>
            <span>累计行人数量：</span>
            <span>{{ paramsObj.pedestrianNum }}</span>
          </div>
          <div>
            <span>是否报警：</span>
            <span>{{ paramsObj.alarmTag == 0 ? '否' : '是' }}</span>
          </div>
          <div>
            <el-divider content-position="left">探测器1(μSv/h)</el-divider>
            <div class="tcqSty">
              <div>
                <span>本底值：</span>
                <span>{{ paramsObj.probe1BackgroundMs }}</span>
              </div>
              <div>
                <span>剂量率：</span>
                <span>{{ paramsObj.probe1Ms }}</span>
              </div>
              <div>
                <span>阈值：</span>
                <span>{{ paramsObj.probe1ThresholdMs }}</span>
              </div>
            </div>
            <el-divider content-position="left">探测器1(cps)</el-divider>
            <div class="tcqSty">
              <div>
                <span>本底值：</span>
                <span>{{ paramsObj.probe1Background }}</span>
              </div>
              <div>
                <span>计数率：</span>
                <span>{{ paramsObj.probe1Cps }}</span>
              </div>
              <div>
                <span>阈值：</span>
                <span>{{ paramsObj.probe1Threshold }}</span>
              </div>
            </div>
          </div>
          <div>
            <el-divider content-position="left">探测器2(μSv/h)</el-divider>
            <div class="tcqSty">
              <div>
                <span>本底值：</span>
                <span>{{ paramsObj.probe2BackgroundMs }}</span>
              </div>
              <div>
                <span>剂量率：</span>
                <span>{{ paramsObj.probe2Ms }}</span>
              </div>
              <div>
                <span>阈值：</span>
                <span>{{ paramsObj.probe2ThresholdMs }}</span>
              </div>
            </div>
            <el-divider content-position="left">探测器2(cps)</el-divider>
            <div class="tcqSty">
              <div>
                <span>本底值：</span>
                <span>{{ paramsObj.probe2Background }}</span>
              </div>
              <div>
                <span>计数率：</span>
                <span>{{ paramsObj.probe2Cps }}</span>
              </div>
              <div>
                <span>阈值：</span>
                <span>{{ paramsObj.probe2Threshold }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancles">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  deviceQueryWithPage,
  recordGdsQuery,
  radAnalysisGet,
  recordNuclideQuery,
  recordDsaQuery,
  recordDoseQuery,
  recordCircuitQuery,
  recordSurfaceQuery,
  recordHandFootQuery,
  recordNeutronQuery,
  recordRecordChannelQuery,
  recordChannelGetById,
  radRecordGdsDownloadExcel,
  radRecordNuclideDownloadExcel,
  radRecordDsaDownloadExcel,
  radRecordDoseDownloadExcel,
  radRecordSurfaceDownloadExcel,
  radRecordHandFootDownloadExcel,
  radRecordCircuitDownloadExcel,
  radRecordNeutronDownloadExcel,
  radRecordChannelDownloadExcel,
  queryThreshold,
  radRecordPedestrianChannelQuery,
  radRecordPedestrianChannelDownloadExcel,
  radRecordDrinkWaterQuery,
  radRecordDrinkWaterDownloadExcel,
  radRecordDecayQuery,
  radDecayRecordDownloadExcel,
} from '@/util/http'
import { getMonValue, getDaysByYear } from '@/util/echartsData'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //列表
      cgxqy: '', // 查询区域名称
      cjgmc: '', // 查询机构名称
      csbmc: '', // 查询设备名称
      cSuperiorArr: [], // 查询区域名称数组
      cjgmcArr: [], // 查询机构名称数组
      csbmcArr: [], // 查询设备名称数组
      value2: ['', ''], //查询日期
      tableArr: [
        { prop: 'areaName', label: '区域名称' },
        { prop: 'organizationName', label: '机构名称' },
        { prop: 'deviceName', label: '设备名称' },
        { prop: 'doserate', label: '辐射剂量(μSv/h)' },
        { prop: 'createDt', label: '产生时间' },
      ], //表格字段
      list: [
        {
          id: 1,
          dataTimes: [],
          dataMax: [],
          dataMin: [],
          dataAvg: [],
          dataY: [],
          name: '',
        },
      ], // 图表值
      interval: 0, //x轴之间的间隔
      rotate: 7, //x轴旋转的角度
      stypes: '', //日期选择类型
      dateranges: '', //日期类型
      daymonth: true, //按天和按月年之间的切换
      placeholders: '', //按月年的提示文本
      formats: '', //按月年的显示格式
      values: '', //按月年的值的格式
      keys: '', //按月年的key值
      newvals: '', //最新的一条数据的时间
      changes: true, //列表图表切换
      ctype: [], //父传子
      option: {
        title: { text: '', left: 'center', top: '0' },
        tooltip: {
          trigger: 'axis', //触发类型
          backgroundColor: 'rgba(255,255,255,1)', //背景
          textStyle: { color: 'black' },
          axisPointer: {
            //鼠标更随
            type: 'cross',
            label: {
              backgroundColor: '#fff',
              color: '#000',
              borderColor: '#aaa',
              borderWidth: '1',
            },
          },
        },
        legend: {
          data: ['最大值', '平均值', '最小值'],
          top: '30',
        },
        grid: {
          left: '3%',
          right: '8%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          //右上角功能图标
          feature: {
            magicType: { show: true, type: ['bar', 'line'] },
            restore: {},
            saveAsImage: {}, // 导出为图片
          },
        },
        xAxis: {
          type: 'category',
          name: '日期',
          boundaryGap: false,
          data: [],
          textStyle: { fontSize: 10 },
          axisLabel: {
            color: '#000',
            interval: this.interval,
            rotate: this.rotate,
          },
        },
        yAxis: {
          type: 'value',
          name: '总值(μSv/h)',
          axisLine: { show: true },
          axisLabel: { show: true, color: '#000' },
          axisTick: { show: true },
        },
        series: [
          { name: '最大值', type: 'line', stack: '最大值', data: [] },
          { name: '平均值', type: 'line', stack: '平均值', data: [] },
          { name: '最小值', type: 'line', stack: '最小值', data: [] },
        ],
      },
      user: this.$store.state.user,
      htmlTitle: '', // 导出pdf文件名
      isShow: false,
      chartOption: {
        title: { text: '', left: 'center', top: '20' },
        xAxis: { type: 'category', data: [] },
        tooltip: {
          transitionDuration: 0, //防抖动
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          axisPointer: {
            //鼠标更随
            type: 'cross',
            label: {
              backgroundColor: '#ddd',
              color: '#000',
              borderColor: '#aaa',
              borderWidth: '1',
            },
          },
        },
        grid: {
          top: '11%',
          left: '3%',
          right: '5%',
          bottom: '5%',
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true },
            restore: { show: true },
            magicType: { type: ['line', 'bar'] },
          },
        },
        yAxis: {
          type: 'value',
          name: 'μSv/h',
          nameGap: 5,
          nameTextStyle: { padding: [0, 0, 3, -30] },
          axisTick: { show: true },
          axisLine: { show: true },
        },
        dataZoom: [
          {
            show: true,
            backgroundColor: 'rgba(255,255,255,0.3)',
            fillerColor: 'rgba(18,150,219,0.3)',
            textStyle: { color: '#000' },
            height: '20px',
            bottom: '2%',
            // start: 90,
            // end: 100,
            handleIcon:
              'path://M512,981.3333129882812C251.73333740234375,981.3333129882812,42.66666793823242,772.2666625976562,42.66666793823242,512S251.73333740234375,42.66666793823242,512,42.66666793823242s469.33331298828125,209.06666946411133,469.33331298828125,469.3333320617676s-209.066650390625,469.33331298828125,-469.33331298828125,469.33331298828125Zm128,-639.9999694824219c-25.5999755859375,0,-42.66668701171875,17.066650390625,-42.66668701171875,42.666656494140625l0,256c0,25.5999755859375,17.06671142578125,42.66668701171875,42.66668701171875,42.66668701171875s42.66668701171875,-17.06671142578125,42.66668701171875,-42.66668701171875L682.6666870117188,384c0,-25.600006103515625,-17.06671142578125,-42.666656494140625,-42.66668701171875,-42.666656494140625ZM384,341.3333435058594c-25.600006103515625,0,-42.666656494140625,17.066650390625,-42.666656494140625,42.666656494140625l0,256c0,25.5999755859375,17.066650390625,42.66668701171875,42.666656494140625,42.66668701171875s42.666656494140625,-17.06671142578125,42.666656494140625,-42.66668701171875L426.6666564941406,384c0,-25.600006103515625,-17.066650390625,-42.666656494140625,-42.666656494140625,-42.666656494140625Z',
            handleColor: '#1296db',
            handleSize: '100%',
          },
          {
            type: 'inside',
          },
        ],
        series: [],
        // visualMap: {
        //   pieces: [
        //     {
        //       lt: this.$threshold.gdsCode()[1],
        //       color: 'red',
        //     },
        //     {
        //       gte: this.$threshold.gdsCode()[1],
        //       lte: this.$threshold.gdsCode()[0],
        //       color: '#5470c6',
        //     },
        //     {
        //       gt: this.$threshold.gdsCode()[0],
        //       color: 'red',
        //     },
        //   ],
        //   left: 'center',
        //   top: '25',
        //   orient: 'horizontal',
        //   show: false,
        // },
      },
      chartObj: {
        myChartpol1: null,
        myChartpol2: null,
        myChartpol3: null,
        myChartpol4: null,
      },
      myChartObj: {
        myChartpol1: null,
        myChartpol2: null,
        myChartpol3: null,
        myChartpol4: null,
      },
      tooltipTitle: '',
      chartNum: 1, // 图表个数
      dialogVisible: false,
      paramsObj: {},
      imgSrc: '', // 图片
      srcList: [], // 图片预览
      plateType: '',
      alarmTag: '',
      plateTypeArr: [
        { name: '无车牌', id: 0 },
        { name: '蓝牌', id: 1 },
        { name: '黄牌', id: 2 },
      ],
      plateNum: '', // 车牌号码
      isText: false,
      thresholdArr: [],
      csblxArr: JSON.parse(sessionStorage.getItem('deviceType')), //设备类型数组
      csblx: '',
      csbmcCh: '',
      personDevArr: [],
      personDev: '',
      seaName: '',
      thresholdArrAll: [],
      columnArr: [],
    }
  },
  computed: {
    ids() {
      return this.$store.state.deviceIds
    },
  },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.cgxqy = msg[0]
      this.cjgmc = msg[1]
      this.csbmc = ''
      this.csbmcArr = []
      if (msg[0] && msg[1]) {
        let params = {
          type: this.ctype[0],
          areaId: this.cgxqy,
          organizationId: this.cjgmc,
          pageNo: 1,
          pageSize: 10000,
        }
        deviceQueryWithPage(params).then((data) => {
          this.csbmcArr = data.result.records
          if (this.ids.length) {
            this.csbmc = this.ids[2]
            this.searchs(1, 10)
          }
        })
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      // 判断图表框是否显示
      if (this.ctype[0] == 17) {
        document.getElementsByClassName('chartSty')[0].style.display = this.csbmc ? 'flex' : 'none'
      } else {
        document.getElementsByClassName('chartSty')[0].style.display = this.csbmc ? 'block' : 'none'
      }
      this.isShow = Boolean(this.csbmc)
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      if (this.changes) {
        let params = {
          queryBeginDt: this.value2[0],
          queryEndDt: this.value2[1],
          areaId: this.cgxqy || this.ids[0],
          organizationId: this.cjgmc || this.ids[1],
          deviceId: this.csbmc || this.ids[2] || this.personDev,
          pageNo: currpage,
          pageSize: pagesize,
          name: this.csbmcCh,
          type: this.csblx,
        }
        if (this.ctype[0] == 1) {
          await recordGdsQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 2) {
          await recordNuclideQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 3) {
          await recordDsaQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 4) {
          await recordDoseQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.tableData.forEach((v) => {
                v.areaName ||= '[暂未绑定]'
                v.organizationName ||= '[暂未绑定]'
              })
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 5) {
          await recordSurfaceQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 6) {
          params.name = this.seaName
          await recordHandFootQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 7) {
          await recordCircuitQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 8) {
          await recordNeutronQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 17) {
          params.licensePlateType = this.plateType
          params.licensePlateNumber = this.plateNum
          params.alarmTag = this.alarmTag
          await recordRecordChannelQuery(params)
            .then((data) => {
              let res = data.result
              res.records.forEach((v) => {
                v.licensePlateCircuit = JSON.parse(v.licensePlateCircuit)
              })
              this.tableData = res.records
              this.totals = res.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 18) {
          await radRecordPedestrianChannelQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 19) {
          await radRecordDrinkWaterQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        } else if (this.ctype[0] == 20) {
          await radRecordDecayQuery(params)
            .then((data) => {
              this.tableData = data.result.records
              this.totals = data.result.total
            })
            .catch(() => {})
        }
        if (this.tableData.length) {
          this.newvals = this.tableData[0].createDt.slice(0, 10)
        }
        // 设备存在
        if (this.isShow) {
          // console.log(this.thresholdArr)
          if (this.ctype[0] == 17) {
            document.getElementsByClassName('chartSty')[0].style.display = this.csbmc ? 'flex' : ''
          } else {
            document.getElementsByClassName('chartSty')[0].style.display = this.csbmc ? 'block' : ''
          }
          var echartTime = []
          let resData = JSON.parse(JSON.stringify(this.tableData))
          if (!resData.length) {
            this.isShow = false
            this.$message.error('暂无数据')
            loading.close()
            return
          }
          this.tooltipTitle = this.tableData[0].deviceName
          // type == 1,2,3,4,7
          if (this.chartNum == 1) {
            // 手足
            if (this.ctype[0] == 6 || this.ctype[0] == 18 || this.ctype[0] == 20) {
              var seriesName = [],
                seriesLabel = [],
                seriesDataA = []
              if (this.ctype[0] == 6) {
                seriesName = ['左手手背β活度', '左手手背γ剂量率', '左手手心β活度', '左手手心γ剂量率', '右手手背β活度', '右手手背γ剂量率', '右手手心β活度', '右手手心γ剂量率', '左脚β活度', '左脚γ剂量率', '右脚β活度', '右脚γ剂量率']
                seriesLabel = ['leftBackBeta', 'leftBackGamma', 'leftPlamBeta', 'leftPlamGamma', 'rightBackBeta', 'rightBackGamma', 'rightPlamBeta', 'rightPlamGamma', 'leftFootBeta', 'leftFootGamma', 'rightFootBeta', 'rightFootGamma']
              }
              if (this.ctype[0] == 18) {
                // seriesName = ['探测器1本底值', '探测器1剂量率', '探测器1阈值', '探测器2本底值', '探测器2剂量率', '探测器2阈值']
                seriesName = ['探测器1本底值', '探测器1计数率', '探测器1阈值', '探测器2本底值', '探测器2计数率', '探测器2阈值']
                seriesLabel = ['probe1Background', 'probe1Cps', 'probe1Threshold', 'probe2Background', 'probe2Cps', 'probe2Threshold']
                // seriesLabel = ['probe1BackgroundMs', 'probe1Ms', 'probe1ThresholdMs', 'probe2BackgroundMs', 'probe2Ms', 'probe2ThresholdMs']
              }
              if (this.ctype[0] == 20) {
                seriesName = ['降解槽', '衰变槽1', '衰变槽2', '衰变槽3', '衰变槽4', '衰变槽5', '衰变槽6', '取样器']
                seriesLabel = ['c0Height', 'c1Height', 'c2Height', 'c3Height', 'c4Height', 'c5Height', 'c6Height', 'dose']
              }
              for (var i = 0; i < seriesName.length; i++) {
                let obj = {
                  data: [],
                  name: seriesName[i],
                  type: 'line',
                  symbolSize: 10,
                }
                seriesDataA.push(obj)
              }
              resData.reverse().forEach((v) => {
                echartTime.push(v.createDt)
                seriesDataA.forEach((item, index) => {
                  item.data.push(v[seriesLabel[index]])
                })
              })
            } else {
              var seriesDataA = [[]]
              resData.reverse().forEach((v) => {
                echartTime.push(v.createDt)
                seriesDataA[0].push(v.doserate)
              })
              this.isText = resData.some((v) => v.doserate < Number(this.thresholdArr[0]) || v.doserate > Number(this.thresholdArr[1]))
            }
            var arr = ['emain1']
            var myChartpol = ['myChartpol1']
            var titleText = ['']
            var charpol = [{}]
          } else if (this.chartNum == 2) {
            // type == 5,8
            var seriesDataA = [[], []]
            var titleText = ['', '']
            resData.reverse().forEach((v) => {
              echartTime.push(v.createDt)
              if (this.ctype[0] == 5) {
                seriesDataA[0].push(v.alpha)
                seriesDataA[1].push(v.beta)
                titleText = ['α', 'β']
              } else {
                seriesDataA[0].push(v.gamma)
                seriesDataA[1].push(v.neutron)
                titleText = ['γ', '中子']
              }
            })
            var arr = ['emain1', 'emain2']
            var myChartpol = ['myChartpol1', 'myChartpol2']
            var charpol = [{}, {}]
          } else if (this.chartNum == 4) {
            // type == 6
            var seriesDataA = [[], [], [], []]
            resData.reverse().forEach((v) => {
              echartTime.push(v.createDt)
              seriesDataA[0].push(v.leftFootGamma)
              seriesDataA[1].push(v.leftHandGamma)
              seriesDataA[2].push(v.rightFootGamma)
              seriesDataA[3].push(v.rightHandGamma)
            })
            var arr = ['emain1', 'emain2', 'emain3', 'emain4']
            var myChartpol = ['myChartpol1', 'myChartpol2', 'myChartpol3', 'myChartpol4']
            var titleText = ['左脚γ', '左手γ', '右脚γ', '右手γ']
            var charpol = [{}, {}, {}, {}]
          } else if (this.chartNum == 6) {
            // type == 17
            var seriesDataA = [
              [[], []],
              [[], []],
              [[], []],
              [[], []],
              [[], []],
              [[], []],
            ]
            resData.reverse().forEach((v, i) => {
              echartTime.push(v.createDt)
              seriesDataA[0][0].push(v.licensePlateCircuit.probe1.probeCPSThreshold)
              seriesDataA[0][1].push(v.licensePlateCircuit.probe1.probeCPS)

              seriesDataA[1][0].push(v.licensePlateCircuit.probe2.probeCPSThreshold)
              seriesDataA[1][1].push(v.licensePlateCircuit.probe2.probeCPS)

              seriesDataA[2][0].push(v.licensePlateCircuit.probe5.probeCPSThreshold)
              seriesDataA[2][1].push(v.licensePlateCircuit.probe5.probeCPS)

              seriesDataA[3][0].push(v.licensePlateCircuit.probe6.probeCPSThreshold)
              seriesDataA[3][1].push(v.licensePlateCircuit.probe6.probeCPS)

              seriesDataA[4][0].push(v.licensePlateCircuit.probe3.probeCPSThreshold)
              seriesDataA[4][1].push(v.licensePlateCircuit.probe3.probeCPS)

              seriesDataA[5][0].push(v.licensePlateCircuit.probe4.probeCPSThreshold)
              seriesDataA[5][1].push(v.licensePlateCircuit.probe4.probeCPS)
            })
            var arr = ['emain1', 'emain2', 'emain3', 'emain4', 'emain5', 'emain6']
            var myChartpol = ['myChartpol1', 'myChartpol2', 'myChartpol3', 'myChartpol4', 'myChartpol5', 'myChartpol6']
            var titleText = ['1#γ探测器', '2#γ探测器', '3#γ探测器', '4#γ探测器', '1#中子探测器', '2#中子探测器']
            var charpol = [{}, {}, {}, {}, {}, {}]
          }
          this.chartOption.xAxis.data = echartTime
          arr.map((item, index) => {
            if (this.chartObj[myChartpol[index]] == null) {
              this.chartObj[myChartpol[index]] = this.$echarts.getInstanceByDom(this.$refs[item][0])
            }
            this.chartObj[myChartpol[index]] = this.$echarts.init(this.$refs[item][0])
            charpol[index] = this.chartOption
          })
          for (let i = 0; i < arr.length; i++) {
            this.chartObj[myChartpol[i]].clear()
            charpol[i].title.top = 7
            charpol[i].grid.bottom = '10%'
            if (this.ctype[0] == 17) {
              charpol[i].color = ['green', 'red']
              charpol[i].title.text = titleText[i] + '监测数据报告'
              charpol[i].series = [
                {
                  data: seriesDataA[i][1],
                  name: titleText[i] + '计数',
                  type: 'line',
                  symbolSize: 10,
                },
                {
                  data: seriesDataA[i][0],
                  name: titleText[i] + '计数阈值',
                  type: 'line',
                  symbolSize: 10,
                },
              ]
            } else {
              charpol[i].title.text = this.tooltipTitle + titleText[i] + '剂量率监测数据报告'
              if (this.ctype[0] == 6 || this.ctype[0] == 18 || this.ctype[0] == 20) {
                charpol[i].legend = { data: seriesName, top: 30 }
                charpol[i].series = seriesDataA
              } else {
                charpol[i].series = [
                  {
                    data: seriesDataA[i],
                    name: this.tooltipTitle,
                    type: 'line',
                    symbolSize: 10,
                  },
                ]
              }
            }
            charpol[i] && this.chartObj[myChartpol[i]].setOption(charpol[i], true)
            window.addEventListener('resize', () => {
              this.chartObj[myChartpol[i]].resize()
            })
          }
        }
      } else {
        this.drawLine()
      }
      loading.close()
    },
    // 实例化图表
    async drawLine() {
      if (this.value2.length == 7) {
        //按月查询
        var starttimes = this.value2 + '-01'
        var endtimes = this.value2 + '-' + getDaysByYear(this.value2.slice(0, 4))[this.value2.slice(-2) - 1]
        var months = []
        months[0] = starttimes.toString()
        months[1] = endtimes.toString()
        this.interval = 2
        this.rotate = 15
      } else if (this.value2.length == 4) {
        //按年
        days = 365
        var years = []
        years[0] = this.value2 + '-01-01'
        years[1] = this.value2 + '-12-31'
      } else {
        //按天
        if (this.value2[0] != null) {
          // 转成标准时间进行加减
          let startDate = new Date(Date.parse(this.value2[0].replace(/-/g, '/')))
          let endDate = new Date(Date.parse(this.value2[1].replace(/-/g, '/')))
          var days = (endDate - startDate) / 86400000 //获取用户选择的天数
        }
        if (days > 33 && days < 360 && days != undefined) {
          //查询天、月，根据天数个数旋转日期角度
          this.$message({
            message: '查询跨度大，请重新选择日期类型',
            type: 'error',
          })
          this.value2 = ''
          this.interval = 0
          this.rotate = 7
        } else if (days >= 10 && days != undefined) {
          this.interval = 1
          this.rotate = days / 2
        } else {
          this.interval = 0
          this.rotate = 0
        }
      }
      if (months != undefined) {
        //按月
        var params = {
          begin: months[0],
          end: months[1],
        }
      } else {
        if (years != undefined) {
          // 按年
          var params = {
            begin: years[0],
            end: years[1],
          }
        } else {
          //按天
          if (this.newvals != '') {
            var dayend = this.newvals
            // 化成时间戳进行加减
            let sjc = new Date(this.newvals).getTime() - 3600 * 1000 * 24 * 6
            // 时间戳化成时间,转成数组判断月日的长度进行动态加0
            let sjday = new Date(sjc).toLocaleDateString().split('/')
            sjday[1].length == 1 ? (sjday[1] = '0' + sjday[1]) : sjday[1]
            sjday[2].length == 1 ? (sjday[2] = '0' + sjday[2]) : sjday[2]
            var daysta = sjday.join('-')
            if (this.value2 == '') {
              var params = {
                begin: daysta,
                end: dayend,
              }
            } else {
              var params = {
                begin: this.value2[0],
                end: this.value2[1],
              }
            }
          }
        }
      }
      if (this.ctype[0] == 1) {
        this.list[0].name = '1001固定式'
      } else if (this.ctype[0] == 2) {
        this.list[0].name = '核素药物残留活度监测仪'
      } else if (this.ctype[0] == 3) {
        this.list[0].name = 'DSA个人剂量腕式数据'
      } else if (this.ctype[0] == 4) {
        this.list[0].name = '个人剂量腕式数据'
      } else if (this.ctype[0] == 7) {
        this.list[0].name = 'X、Y辐射率巡测仪数据'
      }
      var indexNum = []
      if (this.ctype[0] == 5) {
        indexNum = [3, 4]
      } else if (this.ctype[0] == 6) {
        indexNum = [7, 8, 9, 10]
      } else if (this.ctype[0] == 8) {
        indexNum = [5, 6]
      } else {
        indexNum = [0]
      }
      for (let j = 0; j < this.list.length; j++) {
        this.list[j].dataTimes = []
        this.list[j].dataMax = []
        this.list[j].dataMin = []
        this.list[j].dataAvg = []
        this.list[j].dataY = []
        params.deviceType = this.ctype[0]
        params.index = indexNum[j]
        await radAnalysisGet(params).then((data) => {
          if (data) {
            let datas = data.result
            if (days > 360) {
              //按年
              for (let k = 1; k < 13; k++) {
                //循环x轴的各个月份
                k < 10 ? (k = '0' + k) : k
                this.list[j].dataTimes.push(this.value2 + '-' + k)
              }
              this.list[j].dataMax = getMonValue(datas, 0, this.value2)
              this.list[j].dataMin = getMonValue(datas, 1, this.value2)
              this.list[j].dataAvg = getMonValue(datas, 2, this.value2)
              this.list[j].dataY = Math.max.apply(null, this.list[j].dataMax)
              this.interval = 0
              this.rotate = 0
            } else {
              //按天或按月
              datas.map((val) => {
                this.list[j].dataTimes.push(val.createDt)
                this.list[j].dataMax.push(val.max)
                this.list[j].dataMin.push(val.min)
                this.list[j].dataAvg.push(val.avg)
              })
              this.list[j].dataY = Math.max.apply(null, this.list[j].dataMax)
            }
          }
        })
      }
      if (this.chartNum == 1) {
        var arrs = ['chart1']
        var myChartpols = ['myChartpol1']
        var charpols = [{}]
      } else if (this.chartNum == 2) {
        var arrs = ['chart1', 'chart2']
        var myChartpols = ['myChartpol1', 'myChartpol2']
        var charpols = [{}, {}]
      } else if (this.chartNum == 4) {
        var arrs = ['chart1', 'chart2', 'chart3', 'chart4']
        var myChartpols = ['myChartpol1', 'myChartpol2', 'myChartpol3', 'myChartpol4']
        var charpols = [{}, {}, {}, {}]
      }
      arrs.map((item, index) => {
        if (this.myChartObj[myChartpols[index]] == null) {
          this.myChartObj[myChartpols[index]] = this.$echarts.getInstanceByDom(this.$refs[item][0])
        }
        this.myChartObj[myChartpols[index]] = this.$echarts.init(this.$refs[item][0])
        charpols[index] = this.option
      })
      this.$nextTick(() => {
        for (let i = 0; i < arrs.length; i++) {
          this.myChartObj[myChartpols[i]].clear()
          charpols[i].title.text = this.list[i].name
          charpols[i].xAxis.data = this.list[i].dataTimes
          charpols[i].yAxis.max = parseInt(this.list[i].dataY + 2)
          charpols[i].series[0].data = this.list[i].dataMax
          charpols[i].series[1].data = this.list[i].dataAvg
          charpols[i].series[2].data = this.list[i].dataMin
          charpols[i] && this.myChartObj[myChartpols[i]].setOption(charpols[i], true)
          window.addEventListener('resize', () => {
            this.myChartObj[myChartpols[i]].resize()
          })
        }
      })
    },
    // 日期类型切换
    types() {
      if (this.stypes == '0') {
        if (this.ctype[0] == 17 || this.ctype[0] == 18 || this.ctype[0] == 6) {
          this.value2 = [this.timesfun(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), this.timesfun(new Date())]
        } else {
          this.value2 = [this.timesfun(new Date()), this.timesfun(new Date())]
        }
        this.daymonth = true
      } else if (this.stypes == '1') {
        this.value2 = ''
        this.daymonth = false
        this.placeholders = '请选择月'
        this.formats = 'yyyy年MM月'
        this.values = 'yyyy-MM'
        this.dateranges = 'month'
        this.keys = 'month'
      } else if (this.stypes == '2') {
        this.value2 = ''
        this.daymonth = false
        this.placeholders = '请选择年'
        this.formats = 'yyyy年'
        this.values = 'yyyy'
        this.dateranges = 'year'
        this.keys = 'year'
      }
    },
    // 列表图表切换
    switchs() {
      this.changes = !this.changes
      this.value2 = [this.timesfun(new Date()), this.timesfun(new Date())]
      this.drawLine()
      this.daymonth = true
      this.stypes = '0'
      this.types()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.$store.commit('DEVICEIDS', [])
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.personDev = ''
      if (this.ctype[0] == 17 || this.ctype[0] == 18 || this.ctype[0] == 6) {
        this.plateType = ''
        this.plateNum = ''
        this.alarmTag = ''
        this.value2 = [this.timesfun(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), this.timesfun(new Date())]
      } else {
        this.value2 = [this.timesfun(new Date()), this.timesfun(new Date())]
      }
      this.csbmc = ''
      this.seaName = ''
      this.cjgmcArr = []
      this.csbmcArr = []
      this.interval = 0
      this.rotate = 7
      this.stypes = '0'
      this.types()
      this.searchs(1, 10)
    },
    // 导出
    handleClick(e) {
      if (e == 0) {
        // excel
        this.exportes()
      } else {
        // pdf
        this.htmlTitle = this.tooltipTitle + '监测数据报告'
        if (!this.csbmc && document.getElementsByClassName('chartSty')[0].style.display == 'none') {
          this.$message.error('请选择设备点击查询后再导出')
          return
        }
        this.getPdf()
      }
    },
    // 导出Excel
    exportes() {
      let params = {
        queryBeginDt: this.value2[0],
        queryEndDt: this.value2[1],
        areaId: this.cgxqy || this.ids[0],
        organizationId: this.cjgmc || this.ids[1],
        deviceId: this.csbmc || this.ids[2],
        pageNo: 1,
        pageSize: 1000000,
      }
      let reqApi = ''
      let tableHeader = ''
      switch (this.ctype[0]) {
        case 1:
          reqApi = radRecordGdsDownloadExcel
          tableHeader = '1001固定式历史数据详情'
          break
        case 2:
          reqApi = radRecordNuclideDownloadExcel
          tableHeader = '核素药物残留活度监测历史数据详情'
          break
        case 3:
          reqApi = radRecordDsaDownloadExcel
          tableHeader = 'dsa个人剂量腕式历史数据详情'
          break
        case 4:
          reqApi = radRecordDoseDownloadExcel
          tableHeader = '个人剂量仪历史数据详情'
          break
        case 5:
          reqApi = radRecordSurfaceDownloadExcel
          tableHeader = '便携式表面污染仪历史数据详情'
          break
        case 6:
          reqApi = radRecordHandFootDownloadExcel
          tableHeader = '手足表面污染仪历史数据详情'
          break
        case 7:
          reqApi = radRecordCircuitDownloadExcel
          tableHeader = 'X、Y辐射率巡测仪历史数据详情'
          break
        case 8:
          reqApi = radRecordNeutronDownloadExcel
          tableHeader = '二维中子实时探测仪数据详情'
          break
        case 17:
          reqApi = radRecordChannelDownloadExcel
          tableHeader = '通道式辐射监测设备'
          params.licensePlateType = this.plateType
          params.licensePlateNumber = this.plateNum
          params.alarmTag = this.alarmTag
          break
        case 18:
          reqApi = radRecordPedestrianChannelDownloadExcel
          tableHeader = '行人通道式辐射监测设备'
          break
        case 19:
          reqApi = radRecordDrinkWaterDownloadExcel
          tableHeader = '直饮水监测设备'
        case 20:
          reqApi = radDecayRecordDownloadExcel
          tableHeader = '衰变槽设备'
      }
      reqApi(params).then((res) => {
        // console.log(res)
        if (res) {
          const blob = new Blob([res])
          var a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = tableHeader + '.xlsx'
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
      })
    },
    // 17 表格详情
    async rowClick(row) {
      // console.log(row)
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      // imgSrc
      await recordChannelGetById({ id: row.id }).then((res) => {
        if (res) {
          this.imgSrc = res.result.licensePlateBase64
          this.srcList = [this.imgSrc]
        }
        loading.close()
      })
      row.alarmTagChi = row.alarmTag == 0 ? '绿' : '红'
      let colr = row.licensePlateType == 1 ? '蓝 ' : row.licensePlateType == 2 ? '黄 ' : '无 '
      row.licensePlateNumberColor = colr + row.licensePlateNumber
      this.dialogVisible = true
      this.paramsObj = row
    },
    // 18 表格详情
    openDialog(row) {
      this.dialogVisible = true
      this.paramsObj = row
    },
    // 关闭模态框
    cancles() {
      this.dialogVisible = false
      this.paramsObj = {}
    },
    // 时间格式化
    timesfun(times) {
      let date = new Date(times)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      month = month < 10 ? `0${month}` : month
      strDate = strDate < 10 ? `0${strDate}` : strDate
      return year + '-' + month + '-' + strDate
    },
    // 获取单个阈值
    getThreshold() {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        deviceType: 1,
      }
      queryThreshold(params).then((data) => {
        if (data) {
          let res = data.result.records
          this.thresholdArr = [res[0].code, res[1].code]
        }
      })
    },
    // 获取所有阈值
    getThresholdAll() {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        deviceType: this.ctype[0],
      }
      queryThreshold(params).then((data) => {
        if (data) {
          let res = data.result.records
          // console.log(res)
          // res里面每两项分为一组
          res.forEach((v, i) => {
            if (i % 2 == 0) {
              this.thresholdArrAll.push([v.code, res[i + 1].code])
            }
          })
          // console.log(this.thresholdArrAll)
        }
      })
    },
    // 设备名称
    devArr() {
      let params = {
        type: 4,
        pageNo: 1,
        pageSize: 10000,
        // useFlag: 1,
      }
      deviceQueryWithPage(params).then((data) => {
        this.personDevArr = data.result.records
        this.personDev = this.ids[2]
      })
    },
    formatters(flag, e) {
      if (this.ctype[0] == 6) {
        if (flag == 0) {
          if (e == 'createDt') return 'right'
        } else {
          let arr = ['leftBackBeta', 'leftBackGamma', 'leftPlamBeta', 'leftPlamGamma', 'rightBackBeta', 'rightBackGamma', 'rightPlamBeta', 'rightPlamGamma']
          if (arr.includes(e)) {
            return 120
          } else {
            return 100
          }
        }
      }
    },
  },
  props: ['datas'],
  watch: {
    '$store.state.isCollapse': {
      handler(newVal, oldVal) {
        setTimeout(() => {
          for (let i = 1; i < 5; i++) {
            if (this.chartObj['myChartpol' + i]) this.chartObj['myChartpol' + i].resize()
          }
        }, 500)
      },
    },
    datas: {
      handler(newValue, oldValue) {
        this.ctype = newValue
        if (newValue[0] == 4) {
          this.tableArr.forEach((v) => {
            if (v.prop == 'doserate') v.label = '辐射剂量(nSv/h)'
          })
        } else if (newValue[0] == 5) {
          // 便携式表面污染仪
          this.tableArr = [
            { prop: 'areaName', label: '区域名称' },
            { prop: 'organizationName', label: '机构名称' },
            { prop: 'deviceName', label: '设备名称' },
            { prop: 'userName', label: '设备使用者' },
            { prop: 'alpha', label: 'α测量值' },
            { prop: 'beta', label: 'β测量值' },
            { prop: 'placeName', label: '巡检地点' },
            { prop: 'unit', label: '计量单位' },
            { prop: 'createDt', label: '产生时间' },
          ]
          this.list = [
            {
              id: 1,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: 'α测量值',
            },
            {
              id: 2,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: 'β测量值',
            },
          ]
          this.chartNum = 2
        } else if (newValue[0] == 6) {
          // 手足表现污染仪
          this.list = [
            {
              id: 1,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '左手γ剂量率',
            },
            // {
            //   id: 2,
            //   dataTimes: [],
            //   dataMax: [],
            //   dataMin: [],
            //   dataAvg: [],
            //   dataY: [],
            //   name: '右手γ剂量率',
            // },
            // {
            //   id: 3,
            //   dataTimes: [],
            //   dataMax: [],
            //   dataMin: [],
            //   dataAvg: [],
            //   dataY: [],
            //   name: '左脚γ剂量率',
            // },
            // {
            //   id: 4,
            //   dataTimes: [],
            //   dataMax: [],
            //   dataMin: [],
            //   dataAvg: [],
            //   dataY: [],
            //   name: '右脚γ剂量率',
            // },
          ]
          this.chartNum = 1
        } else if (newValue[0] == 8) {
          // 中子探测器
          this.tableArr = [
            { prop: 'areaName', label: '区域名称' },
            { prop: 'organizationName', label: '机构名称' },
            { prop: 'deviceName', label: '设备名称' },
            // { prop: 'gamma', label: 'γ剂量率(μSv/h)' },
            { prop: 'neutron', label: '中子剂量率(μSv/h)' },
            { prop: 'createDt', label: '产生时间' },
          ]
          this.list = [
            // {
            //   id: 5,
            //   dataTimes: [],
            //   dataMax: [],
            //   dataMin: [],
            //   dataAvg: [],
            //   dataY: [],
            //   name: 'γ剂量率',
            // },
            {
              id: 6,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '中子剂量率',
            },
          ]
          this.chartNum = 1
        } else if (newValue[0] == 17) {
          this.list = [
            {
              id: 1,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: 'γ1#探测器',
            },
            {
              id: 2,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '右手γ剂量率',
            },
            {
              id: 3,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '左脚γ剂量率',
            },
            {
              id: 4,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '右脚γ剂量率',
            },
            {
              id: 5,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '左脚γ剂量率',
            },
            {
              id: 6,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '右脚γ剂量率',
            },
          ]
          this.chartNum = 6
        } else if (newValue[0] == 18) {
          this.chartNum = 1
          this.list = [
            {
              id: 1,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: 'γ1#探测器',
            },
          ]
        } else if (newValue[0] == 19) {
          this.chartNum = 1
          this.list = [
            {
              id: 1,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: 'γ1#探测器',
            },
          ]
        } else if (newValue[0] == 20) {
          this.chartNum = 1
          this.columnArr = [
            { prop: 'c0Height', label: '降解槽(m)' },
            { prop: 'c1Height', label: '衰变槽1(m)' },
            { prop: 'c2Height', label: '衰变槽2(m)' },
            { prop: 'c3Height', label: '衰变槽3(m)' },
            { prop: 'c4Height', label: '衰变槽4(m)' },
            { prop: 'c5Height', label: '衰变槽5(m)' },
            { prop: 'c6Height', label: '衰变槽6(m)' },
            { prop: 'dose', label: '取样器(μSv/h)' },
          ]
          this.list = [
            {
              id: 1,
              dataTimes: [],
              dataMax: [],
              dataMin: [],
              dataAvg: [],
              dataY: [],
              name: '衰变槽',
            },
          ]
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听日期类型
    value2(newVal) {
      if (newVal == null) {
        this.value2 = ['', '']
      }
    },
  },
  mounted() {
    this.getThreshold()
    if (this.ctype[0] != 18) this.getThresholdAll()
    if (this.ctype[0] == 17 || this.ctype[0] == 18 || this.ctype[0] == 6) {
      this.value2 = [this.timesfun(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), this.timesfun(new Date())]
    } else {
      this.value2 = [this.timesfun(new Date()), this.timesfun(new Date())]
    }
    document.getElementsByClassName('chartSty')[0].style.display = 'none'
    if (!this.ids.length || !this.ids[1]) {
      this.searchs(1, 10)
    }
    if (this.ctype[0] == 4) {
      this.devArr()
    }
  },
  beforeDestroy() {
    this.$store.commit('DEVICEIDS', [])
  },
}
</script>
<style src="../../../public/css/search.less" lang="less" scoped></style>
<style src="../../../public/css/echarts.css"></style>
<style lang="less" scoped>
.titles {
  > div {
    width: 100%;
  }
}
.cont {
  width: 100%;
}
// 图表
#myChart {
  width: 82vw;
  height: 36vw;
}
.echartsBox div {
  margin: var(--margin-xy);
}
// .el-dropdown-link {
//   cursor: pointer;
//   color: #fff;
//   background: #409eff;
//   height: 28px;
//   border-radius: 3px;
//   line-height: 28px;
//   margin: 0 10px;
//   padding: 0px 11px;
// }
// .el-icon-arrow-down {
//   font-size: 12px;
// }
// .icon-excel:before {
//   color: #45b058;
//   font-size: 18px;
//   margin-right: 6px;
// }
// .icon-pdf:before {
//   color: #007ffc;
//   font-size: 18px;
//   margin-right: 6px;
// }
.first {
  height: 100%;
  .chartSty {
    border: 1px solid #ccc;
    height: 87%;
    .emain1,
    .emain2,
    .emain3,
    .emain4,
    .emain5,
    .emain6 {
      width: 100%;
      height: 100%;
    }
  }
}
.chartOne {
  > div {
    width: 100%;
    height: 90%;
  }
}
.chartTwo {
  display: flex;
  > div {
    width: 50%;
  }
  > :first-child {
    border-right: 1px solid #ccc;
  }
}
.chartFour {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    height: 50%;
    box-sizing: border-box;
  }
  > :first-child {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  > :nth-child(2) {
    border-bottom: 1px solid #ccc;
  }
  > :nth-child(3) {
    border-right: 1px solid #ccc;
  }
}
.chartSix {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: calc(100% / 3);
    height: 50%;
    box-sizing: border-box;
  }
  > :first-child {
    border-bottom: 1px solid #ccc;
  }
  > :nth-child(2) {
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  > :nth-child(3) {
    border-bottom: 1px solid #ccc;
  }
  > :nth-last-child(2) {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}
.echartsnone {
  text-align: center;
  color: #aaa;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.second {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .chartSty {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    .chart1 {
      width: 82vw;
      height: 36vw;
    }
  }
  .chartTwo {
    .chart1,
    .chart2 {
      width: 41vw;
      height: 36vw;
    }
  }
  .chartFour {
    .chart1,
    .chart2,
    .chart3,
    .chart4 {
      width: 41vw;
      height: 18vw;
    }
  }
  .chartSix {
    .chart1,
    .chart2,
    .chart3,
    .chart4,
    .chart5,
    .chart6 {
      width: calc(82vw / 3);
      height: 18vw;
    }
  }
}
// 详情模态框
.newDialog {
  height: 520px;
  overflow: auto;
  > div {
    width: 77%;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    margin: 3px auto;
    color: #000;
    font-size: initial;
  }
  /deep/.el-image {
    display: contents;
    img {
      width: 77%;
      margin: auto;
      margin-bottom: 10px;
    }
  }
  .el-input {
    width: 290px;
  }
  .bgc {
    background: #fb6464;
    color: #fff;
  }
}
.textSty {
  font-size: 0.8em;
  text-align: center;
}
.isWarn {
  background: red;
  color: #fff;
}
.tcqSty {
  display: flex;
  > div {
    width: calc(100% / 3);
  }
}
</style>
